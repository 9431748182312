import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import localeUk from "@angular/common/locales/en-GB";
import localeEs from "@angular/common/locales/es";
import localeFr from "@angular/common/locales/fr";
import localeNl from "@angular/common/locales/nl";
import localeNo from "@angular/common/locales/no";
import { ErrorHandler, NgModule } from "@angular/core";
import {
  ScreenTrackingService,
  UserTrackingService,
} from "@angular/fire/analytics";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import {
  AngularFirestore,
  AngularFirestoreModule,
} from "@angular/fire/compat/firestore";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { environment } from "../environments/environment";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ApplicationStateService } from "./services/application-state.service";
import { ClientStylesService } from "./services/client-styles.service";
import { CrudLogService } from "./services/crud-log.service";
import { DropdownTrackerService } from "./services/dropdown-tracker.service";
import { ErrorHandlerService } from "./services/error-handler.service";
import { SystemInfoService } from "./services/system-info.service";
import { CustomTranslateLoader } from "./services/translations.service";

registerLocaleData(localeNl);
registerLocaleData(localeUk);
registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeNo);
registerLocaleData(localeFr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    MatNativeDateModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    BrowserAnimationsModule,
    AngularFireAuthModule,
    HttpClientModule,
    MatDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [AngularFirestore],
      },
      defaultLanguage: "en",
    }),
  ],
  providers: [
    CrudLogService,
    DropdownTrackerService,
    ScreenTrackingService,
    UserTrackingService,
    ApplicationStateService,
    ClientStylesService,
    SystemInfoService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "outline" },
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
