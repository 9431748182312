import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ErrorLog } from "src/app/models/error-log";
import { environment } from "src/environments/environment";

import { AppVarsService } from "./app-vars.service";

@Injectable({ providedIn: "root" })
export class MattermostService {
  constructor(private _http: HttpClient, private _appVars: AppVarsService) {}

  /**
   * Sends a Mattermost notification with details of the provided error.
   *
   * @remarks
   * This function constructs a payload containing details of the error, such
   * as client information, error name, message, stack trace, and browser details.
   * It then sends this payload to a Mattermost webhook URL for notification purposes.
   *
   * @param error - The error object containing details in the notification payload.
   * @returns An Observable that emits the HTTP POST request to the Mattermost webhook URL.
   */
  public sendMattermostNotification(error: ErrorLog) {
    // Construct Mattermost webhook URL.
    const mattermostUrl =
      this._appVars.secrets.mattermost_host +
      "hooks/" +
      this._appVars.secrets.mattermost_webhook;

    // Extract relevant data from error object.
    const clientReferenceMetadata = this._appVars.clientReference.metadata;
    const browserInfo = error.browserInfo;
    const appType = sessionStorage.getItem("appType");

    // Determine author name based on app type.
    const authorName =
      appType === "AdP"
        ? "Error logging for Adjuster Portal (AdP)"
        : "Error logging for Customer Portal (CuP)";

    // Construct payload for Mattermost notification.
    const payload = {
      attachments: [
        {
          author_name: authorName,
          author_icon: `${error.clientMetadata?.logo_file}`,
          pretext: `${error.errorName} happened for user: ${clientReferenceMetadata?.username}`,
          color: "#dc3545",
          fields: [
            {
              short: true,
              title: "Client:",
              value: `${error.clientMetadata?.client_name}, (${error.clientMetadata?.client_id})`,
            },
            {
              short: true,
              title: "Client reference:",
              value: `${clientReferenceMetadata?.client_reference}, ${clientReferenceMetadata?.hash}, ${clientReferenceMetadata?.client_reference_id}`,
            },
            {
              short: true,
              title: "Browser urls:",
              value:
                `CuP: ${error.url}\n` +
                `VC Cart: ${environment.valueCheckerUrl}` +
                "cart?icr=" +
                `${clientReferenceMetadata?.client_reference}` +
                "&client_id=" +
                `${error.clientMetadata?.client_id}`,
            },
            {
              short: true,
              title: "Browser info and OS:",
              value: `${browserInfo.browser.name}/${browserInfo.browser.version}, ${browserInfo.device_type} version, ${browserInfo.screen_width}px x ${browserInfo.screen_height}px, ${browserInfo.platform}`,
            },
            {
              short: false,
              title: "Some relevant code issue",
              value:
                "```js\n" +
                `Error Name: ${error.errorName}\n` +
                `Error Message: ${error.errorMessage}\n` +
                `Error Stack: ${error.errorStack}\n` +
                "```",
            },
            {
              short: false,
              title: "",
              value: `[See logs](${this._appVars.secrets.gcp_logs_link})`,
            },
          ],
        },
      ],
    };

    return this._http.post(mattermostUrl, JSON.stringify(payload));
  }
}
