import { Location } from "@angular/common";
import { Component } from "@angular/core";

import { AppVarsService } from "src/app/services/app-vars.service";

@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.css"],
})
export class MessageComponent {
  public emailSubject = encodeURIComponent("BCA Support");
  public supportEmail = this._appVars.secrets?.support_email;

  constructor(private _location: Location, private _appVars: AppVarsService) {}

  /**
   * Navigates back to previous browser history location.
   *
   * @remarks
   * Simulates a browser history navigation to the previous location.
   */
  public goBack(): void {
    this._location.back();
  }
}
