import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { ErrorHandlerService } from "./error-handler.service";

@Injectable({
  providedIn: "root",
})
export class RedirectService {
  constructor(
    private _router: Router,
    private _errorHandlerService: ErrorHandlerService
  ) {}

  /** Redirect on error message page. */
  public redirectOnError() {
    sessionStorage.clear();
    this._router
      .navigate(["/error/message"])
      .catch((error: Error) => this._errorHandlerService.handleError(error));
  }

  /**
   * Redirects the user to the login page with the provided hash.
   *
   * @remarks
   * This function removes the "access_code" from the session storage
   * and navigates the user to the login page with the given hash as a
   * parameter in the URL. If an error occurs during navigation, a snackbar
   * will be displayed showing the error message.
   *
   * @param hash - The hash to be included in the URL of the login page.
   * @returns void
   */
  public redirectToLoginPage(hash: string): void {
    sessionStorage.removeItem("access_code");
    this._router
      .navigate(["/login/", hash])
      .catch((error: Error) => this._errorHandlerService.handleError(error));
  }

  /**
   * Redirects the user to privacy policy page.
   *
   * @remarks
   * This function navigates the user to the privacy policy page with the
   * given hash as a parameter in the URL. If an error occurs during navigation,
   * a snackbar will be displayed showing the error message.
   *
   * @param route - The base URL for the page that the method redirects to.
   * @param hash - The hash value used to identify the user or context.
   */
  public redirectBasedOnPrivacyStatus(route: string, hash: string): void {
    this._router
      .navigate([route, hash])
      .catch((error: Error) => this._errorHandlerService.handleError(error));
  }
}
