import { NgModule } from "@angular/core";
import { Router, RouterModule, Routes } from "@angular/router";

import { MessageComponent } from "./modules/shared/pages/message/message.component";
import { ApplicationStateService } from "./services/application-state.service";
import { mapToResolve } from "./utils/functional-guards";
import { TranslationResolver } from "./utils/translation-resolver";

const desktopRoutes: Routes = [
  { path: ":hash", redirectTo: "app/:hash", pathMatch: "full" },
  {
    path: "error/message",
    resolve: {
      resolveTranslation: mapToResolve(TranslationResolver),
    },
    component: MessageComponent,
  },
  {
    path: "",
    redirectTo: "error/message",
    pathMatch: "full",
  },

  {
    path: "",
    resolve: {
      resolveTranslation: mapToResolve(TranslationResolver),
    },
    loadChildren: () =>
      import("./modules/claimant/claimant.module").then(
        (m) => m.ClaimantModule
      ),
  },
];

const mobileRoutes: Routes = [
  { path: ":hash", redirectTo: "app/:hash", pathMatch: "full" },
  {
    path: "error/message",
    resolve: {
      resolveTranslation: mapToResolve(TranslationResolver),
    },
    component: MessageComponent,
  },
  {
    path: "",
    redirectTo: "error/message",
    pathMatch: "full",
  },

  {
    path: "",
    resolve: {
      resolveTranslation: mapToResolve(TranslationResolver),
    },
    loadChildren: () =>
      import("./modules/claimant-mobile/claimant-mobile.module").then(
        (m) => m.ClaimantMobileModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot([])],
  exports: [RouterModule],
})
export class AppRoutingModule {
  public constructor(
    private router: Router,
    private applicationStateService: ApplicationStateService
  ) {
    const isMobile =
      applicationStateService.isMobileResolution ||
      applicationStateService.isTabletResolution;
    if (isMobile) {
      router.resetConfig(mobileRoutes);
    } else if (!isMobile) {
      applicationStateService.isMobileResolution = false;
      applicationStateService.isTabletResolution = false;
      router.resetConfig(desktopRoutes);
    }
  }
}
