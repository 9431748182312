import { Injectable } from "@angular/core";

import { BrowserInfo } from "../models/browser-info";

@Injectable({
  providedIn: "root",
})
export class SystemInfoService {
  public browserInfo: BrowserInfo;

  constructor() {
    if (!this.browserInfo) this.browserInfo = this.getBrowserInfo();
  }

  /**
   * Retrieves browser information.
   *
   * @returns Browser information.
   */
  public getBrowserInfo(): BrowserInfo {
    const userAgent = navigator.userAgent;

    const browser = this._getBrowserName(userAgent);
    const platform = this._getPlatform(userAgent);
    const screen_width = window.innerWidth;
    const screen_height = window.innerHeight;
    const device_type = this._getDeviceType();

    return {
      browser,
      platform,
      screen_width,
      screen_height,
      device_type,
    };
  }

  /**
   * Extracts the platform information from the user agent string.
   *
   * @param userAgent The user agent string.
   * @returns The detected platform information.
   */
  private _getPlatform(userAgent: string): string {
    // Detect platform based on user agent.
    if (userAgent.includes("Win")) {
      return "Windows";
    } else if (userAgent.includes("Mac")) {
      return "MacOS";
    } else if (userAgent.includes("Linux")) {
      return "Linux";
    } else if (userAgent.includes("Android")) {
      return "Android";
    } else if (userAgent.includes("iOS")) {
      return "iOS";
    } else {
      return "Unknown";
    }
  }

  /**
   * Retrieves browser name and version from user agent.
   *
   * @param userAgent The user agent string.
   * @returns Browser name and version.
   */
  private _getBrowserName(userAgent: string): {
    name: string;
    version: string;
  } {
    const userAgentLowerCase = userAgent.toLowerCase();

    const browserRegex =
      /(chrome|firefox|safari|edge|opera|msie|trident)(?:\/|\s)(\d+(\.\d+)?)/;
    const isMatch = userAgentLowerCase.match(browserRegex);

    if (isMatch) {
      const name = isMatch[1]
        .replace(/\b\w/g, (char: string) => char.toUpperCase())
        .replace("Trident", "Internet Explorer");
      const version = isMatch[2];
      return { name, version };
    } else {
      return { name: "Unknown", version: "Unknown" };
    }
  }

  /**
   * Determines the type of device.
   *
   * @returns Device type.
   */
  private _getDeviceType(): string {
    const screenWidth = window.innerWidth;
    const isMobile = screenWidth <= 768;
    const isTablet = screenWidth > 767 && screenWidth <= 1280;

    if (isMobile) {
      return "Mobile";
    } else if (isTablet) {
      return "Tablet";
    } else {
      return "Desktop";
    }
  }
}
