import { ErrorHandler, Injectable } from "@angular/core";

import { ErrorLog } from "src/app/models/error-log";

import { environment } from "../../environments/environment";

import { ErrorGeneratorService } from "./error-generator.service";
import { GCPService } from "./gcp.service";
import { MattermostService } from "./mattermost.service";

/**
 * Service to handle global errors and log them.
 */
@Injectable({
  providedIn: "root",
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    private _errorGenerator: ErrorGeneratorService,
    private _gCPService: GCPService,
    private _mattermostService: MattermostService
  ) {}

  /**
   * Handles global errors and logs them.
   *
   * @param error - The error object.
   */
  public handleError(error: Error): void {
    const errorLog = this._errorGenerator.generateError(error);
    if (!environment.environment.includes("production")) {
      // eslint-disable-next-line no-console
      console.error(errorLog);
    }
    if (!errorLog?.url?.includes("localhost"))
      this._logErrorToBackend(errorLog);
  }

  /**
   * Logs the error to the backend for further analysis.
   *
   * Also calls _checkAndAddError to add the error to error logs if necessary.
   *
   * @param errorLog - The error log object to be logged.
   */
  private _logErrorToBackend(errorLog: ErrorLog): void {
    this._gCPService
      .sendGCPNotification(this._errorGenerator.getGCPError(errorLog))
      .subscribe();

    if (!this._errorGenerator.checkErrorDuplicity(errorLog)) {
      this._mattermostService.sendMattermostNotification(errorLog).subscribe();
    }
  }
}
