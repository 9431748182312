/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument */
import { Injectable } from "@angular/core";
import { DocumentSnapshot } from "@angular/fire/compat/firestore";
import { Subscription, catchError } from "rxjs";

import { environment } from "../../environments/environment";
import { Inventory } from "../models/inventory";
import { ActionLogEntry, UserInfo } from "../models/session-replay";

import { AppVarsService } from "./app-vars.service";
import { ErrorHandlerService } from "./error-handler.service";
import { GCPService } from "./gcp.service";
import { LogsService } from "./logs.service";
import { SystemInfoService } from "./system-info.service";

export enum CustomFirebaseEvents {
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",
  LOGOUT = "LOGOUT",
  CREATE_ITEM = "CREATE_ITEM",
  UPDATE_ITEM = "UPDATE_ITEM",
  DELETE_ITEM = "DELETE_ITEM",
  UPLOAD_FILES = "UPLOAD_FILES",
  DELETE_TEMP_FILE = "DELETE_TEMP_FILE",
  DELETE_FILES = "DELETE_FILES",
  IMAGE_ANALYSIS_SUCCESS = "IMAGE_ANALYSIS_SUCCESS",
  IMAGE_ANALYSIS_FAIL = "IMAGE_ANALYSIS_FAIL",
  UPDATE_LABELS = "UPDATE_LABELS",
  UPDATE_LINE_ITEMS = "UPDATE_LINE_ITEMS",
  SUBMIT_LIST = "SUBMIT_LIST",
  CONTACT_SUPPORT = "CONTACT_SUPPORT",
}

export const ActionUnrelatedToRow = -1;

@Injectable({
  providedIn: "root",
})
export class CrudLogService {
  public actionLogEntries: ActionLogEntry[] = [];

  constructor(
    public appVars: AppVarsService,
    public gcpService: GCPService,
    private _logs: LogsService,
    private _errorHandler: ErrorHandlerService,
    private _systemInfo: SystemInfoService
  ) {}

  /**
   * Update session replays with a provided session replay and write to Firebase.
   *
   * @remarks
   * After the update, it sends a GCP notice with the old and new inventory
   * values and the action. The eslint overrides in the implementation are
   * necessary because of the broad scope of possible errors that can happen.
   * This makes it difficult to determine the exact type of the error, hence no
   * specific type is provided.
   *
   * @param logEntry - The log entry to write to Firebase.
   */
  public writeLogsInFirebase(logEntry: ActionLogEntry): void {
    /*    this._logs
      .getActionLogs()
      .doc(this.appVars.clientReferenceHash)
      .set(
        {
          entries: [...this.actionLogEntries],
        },
        { merge: true }
      )
      .then(() => {
        if (environment.environment !== "development") {
          this.gcpService
            .sendGCPActionLogNotice(logEntry)
            .pipe(
              catchError((error) => {
                this._errorHandler.handleError(error);
                return null;
              })
            )
            .subscribe();
        }
      })
      .catch((error) => {
        this._errorHandler.handleError(error);
      });*/

    if (environment.environment !== "development") {
      this.gcpService
        .sendGCPActionLogNotice(logEntry)
        .pipe(
          catchError((error) => {
            this._errorHandler.handleError(error);
            return null;
          })
        )
        .subscribe();
    }
  }

  /**
   * Logs an action performed on an inventory item.
   *
   * @remarks
   * This method is responsible for logging an action performed on an inventory item.
   * It first creates a log entry for the action using the `_createLogEntry` method.
   * It then finds the corresponding journal entry in the current session replay's
   * claim data journal. If a journal entry for the item already exists, it adds the
   * new log entry to the existing journal entries. If a journal entry for the item
   * does not exist, it creates a new journal entry with the new log entry and adds
   * it to the claim data journal. Finally, it writes the logs in Firebase using the
   * `writeLogsInFirebase` method.
   *
   * @param eventName - The name of the event.
   * @param rowId - The ID of the row in the inventory where the action was
   *    performed.
   * @param newValue - The new value of the inventory item after the action was
   *    performed.
   * @param insertedPin - The PIN that was inserted by the user who made the
   *   change.
   * @param imageUrl - The URL of the image that was analyzed.
   */
  public logAction(
    eventName: string,
    rowId: number,
    newValue: Inventory,
    insertedPin = "",
    imageUrl = ""
  ): void {
    const logEntry = this._createLogEntry(
      eventName,
      rowId,
      newValue,
      insertedPin,
      imageUrl
    );

    if (!this.actionLogEntries || this.actionLogEntries.length === 0) {
      this.actionLogEntries = [] as ActionLogEntry[];
    }

    this.actionLogEntries?.push(logEntry);

    this.writeLogsInFirebase(logEntry);
  }

  /**
   * Logs the removal of rows from the inventory.
   *
   * @remarks
   * This method iterates over the provided array of inventory items (rows) and
   * for each item:
   * - It finds the corresponding journal entry in the current session replay's
   * claim data journal.
   * - It creates a new log entry for the deletion of the item.
   * - If a journal entry for the item already exists, it adds the new log entry
   * to the existing journal entries.
   * - If a journal entry for the item does not exist, it creates a new journal
   * entry with the new log entry and adds it to the claim data journal.
   * - Finally, it writes the logs in Firebase.
   *
   * @param rows - An array of inventory items (rows) to be removed.
   */
  public logRowsRemoval(rows: Inventory[]): void {
    rows.forEach((item) => {
      const entry = this._createLogEntry(
        CustomFirebaseEvents.DELETE_ITEM,
        item.row_id,
        item
      );

      if (!this.actionLogEntries || this.actionLogEntries.length === 0) {
        this.actionLogEntries = [];
      }
      this.actionLogEntries.push(entry);

      this.writeLogsInFirebase(entry);
    });
  }

  /**
   * Retrieves all session replays.
   *
   * @remarks
   * This method is responsible for fetching all session replays. If the session
   * replays are already available, it returns a dummy subscription.
   * Otherwise, it subscribes to the value changes of the session replays
   * document in Firestore. When the value changes, it updates the
   * `sessionReplays` property with the new data.
   *
   * @returns A subscription to the session replays document in Firestore.
   */
  public getSessionReplays(): Subscription {
    if (this.actionLogEntries && this.actionLogEntries?.length > 0) {
      return this.appVars.getDummySubscription();
    }

    this._logs
      .getActionLogs()
      .doc(this.appVars.clientReferenceHash)
      .get()
      .subscribe((snapshot: DocumentSnapshot<ActionLogEntry>) => {
        if (snapshot.exists) {
          this.actionLogEntries = snapshot.data()[
            "entries"
          ] as ActionLogEntry[];
        }
      });
  }

  /**
   * Creates a log entry for a given event.
   *
   * @remarks
   * This method creates a log entry for a given event. It first compares the
   * old and new inventory values using the `_compareInventories` method.
   * The comparison results are then used to create a new `ClaimDataJournalEntry`
   * object, which includes the event name, the current date and time, and the
   * changes (old and new values). The created `ClaimDataJournalEntry`
   * object is then returned. The method also filters out all the attributes
   * with the `undefined` value since Firebase does not support that data type.
   *
   *
   * @param eventName - The name of the event.
   * @param rowId - The ID of the row in the inventory where the event occurred.
   * @param newValue - The new value of the inventory after the event.
   * @param insertedPin - The PIN that was inserted by the user who made the
   *  change.
   * @param imageUrl - The URL of the image that was analyzed.
   * @returns The created log entry.
   */
  private _createLogEntry(
    eventName: string,
    rowId: number,
    newValue: Inventory,
    insertedPin = "",
    imageUrl = ""
  ): ActionLogEntry {
    const browserInfo =
      this._systemInfo.browserInfo ?? this._systemInfo.getBrowserInfo();

    const userInfo: UserInfo = {
      client_reference_hash: this.appVars.clientReferenceHash,
      client_reference: this.appVars.clientReference.metadata.client_reference,
      client_id: this.appVars.clientId,
      username: this.appVars.clientReference.metadata.username,
    };

    return {
      action: eventName,
      timestamp: new Date(),
      row_id: rowId,
      browser_info: browserInfo,
      changes: JSON.stringify(newValue),
      user_info: userInfo,
      inserted_pin: insertedPin,
      image_url: imageUrl,
    };
  }
}
