import { Type, inject } from "@angular/core";
import { ResolveFn } from "@angular/router";

/**
 * Maps an injectable class with a resolve function to an equivalent
 * `ResolveFn` for use in a `Route` definition.
 *
 * @remarks
 * This function is used to convert an injectable class with a resolve
 * function into an equivalent `ResolveFn` that can be used in a `Route`
 * definition. The `ResolveFn` is responsible for resolving data for
 * a specific route.
 *
 * Usage: {@example router/utils/functional_guards.ts region='Resolve'}
 *
 * @param provider - An injectable class with a resolve function.
 * @returns A ResolveFn function that is capable of resolving data for
 * a route.
 */
export function mapToResolve<T>(
  provider: Type<{ resolve: ResolveFn<T> }>
): ResolveFn<T> {
  return (...params) => inject(provider).resolve(...params);
}
