import {
  animate,
  group,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const SlideInOutAnimation = [
  trigger("slideInOut", [
    state(
      "in",
      style({
        "max-height": "500px",
        opacity: "0.9",
        visibility: "visible",
      })
    ),
    state(
      "out",
      style({
        "max-height": "0px",
        opacity: "0",
        visibility: "hidden",
      })
    ),
    transition("in => out", [
      group([
        animate(
          "500ms ease-in-out",
          style({
            opacity: "0",
            position: "absolute",
            bottom: "-30px",
          })
        ),
      ]),
    ]),
    transition("out => in", [group([animate(1000)])]),
  ]),
];
