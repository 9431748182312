import { Component, ElementRef, HostListener, ViewChild } from "@angular/core";

import { SlideInOutAnimation } from "./animations/SlideInOutAnimations";
import { AppVarsService } from "./services/app-vars.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  animations: [SlideInOutAnimation],
})
export class AppComponent {
  @ViewChild("offlineContainerRef") offlineContainerRef: ElementRef;
  title = "vc-bicc-claimant-app";
  isOnline = false;
  animationState = "in";

  constructor(private _appVars: AppVarsService) {
    this._appVars.getSecrets();
  }

  /** Updates the online status and sets animation state. */
  private _updateOnlineStatus(): void {
    this.isOnline = window.navigator.onLine;
    this.animationState = this.isOnline ? "out" : "in";
  }

  /**
   * Handles the click event on the document.
   *
   * @remarks
   * If the click target is outside the offline container, sets the
   * animation state to "out".
   * @param targetElement - The target element of the click event.
   */
  @HostListener("document:click", ["$event.target"])
  public onClick(targetElement: HTMLElement) {
    let clickedInside: boolean;
    if (this.offlineContainerRef) {
      const offlineContainer = this.offlineContainerRef
        .nativeElement as HTMLElement;
      clickedInside = offlineContainer.contains(targetElement);
    }

    if (!clickedInside) {
      this.animationState = "out";
    }
  }
}
