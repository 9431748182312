import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DropdownTrackerService {
  private _closeDropdownsSubject = new Subject<string>();

  /**
   * Forwards an event to indicate the intention to close dropdowns.
   *
   * @remarks
   * Optionally, an exception can be provided not to close the one specified.
   *
   * @param {string} [exception] The consumers define their exception which doesn't close their dropdown.
   */
  public closeDropdownsEvent(exception?: string): void {
    this._closeDropdownsSubject.next(exception ? exception : "");
  }

  /**
   * Retrieves an observable stream of events indicating the intention to close dropdowns.
   *
   * @remarks
   * Consumers can subscribe to this observable to be notified when dropdowns should be closed.
   *
   * @returns {Observable<string>} An observable stream of events indicating the intention to close dropdowns.
   */
  public getCloseDropdownsEvent(): Observable<string> {
    return this._closeDropdownsSubject.asObservable();
  }
}
