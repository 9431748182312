import { Timestamp } from "../models/error-log";

/**
 * Converts a Firebase timestamp or a Date object to a JavaScript Date object.
 *
 * @param timestamp - The timestamp to convert.
 * @returns A JavaScript Date object representing the timestamp.
 */
export function convertTimestampToDate(timestamp: Timestamp | Date): Date {
  if (timestamp instanceof Date) {
    return timestamp;
  }

  // Convert to milliseconds.
  const milliseconds =
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;

  // Create a Date object.
  const date = new Date(milliseconds);

  return date;
}
