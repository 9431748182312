import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Observable } from "rxjs";

import { AppData, Secrets } from "src/app/models/app-data";

@Injectable({
  providedIn: "root",
})
export class AppDataService {
  constructor(private _firestore: AngularFirestore) {}

  /**
   * Retrieves the entire app data document.
   *
   * @returns An Observable emitting the app data.
   */
  public getAppData(): Observable<AppData> {
    return this._firestore
      .collection("app_data")
      .doc<AppData>("data_input")
      .valueChanges();
  }

  /**
   * Retrieves the secrets field from the frontend document of the app data.
   *
   * @returns An Observable emitting the secrets.
   */
  public getSecrets(): Observable<Secrets> {
    return this._firestore
      .collection("app_data")
      .doc<Secrets>("secrets")
      .valueChanges();
  }
}
