<div class="error-container">
  <h2 class="error-message">
    {{ "bca_client_reference_not_found" | translate }}
  </h2>
  <div class="d-flex w-100 justify-content-center">
    <img
      ngSrc="assets/icons/error.svg"
      alt="Exclamation mark indicating error"
      height="80"
      width="80"
    />
  </div>
  <span class="go-back-text">{{ "bca_go_back_easily" | translate }}</span>
  <button
    class="bca-button bca-button--primary bca-button--wider go-back-button"
    (click)="goBack()"
  >
    {{ "bca_go_back_imperative" | translate }}
  </button>
  <a
    id="contact-support-text"
    class="contact-support-text"
    href="mailto:{{ supportEmail }}?subject={{ emailSubject }}"
  >
    {{ "bca_contact_support" | translate }}
  </a>
</div>
