// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: "0.6.24",
  environment: "development",
  firebase: {
    apiKey: "AIzaSyCKTCPQrNeTnc0R-pkf444LVgDxbB9bLa0",
    authDomain: "vc-bicc-claimant-app-staging.firebaseapp.com",
    projectId: "vc-bicc-claimant-app-staging",
    storageBucket: "vc-bicc-claimant-app-staging.appspot.com",
    messagingSenderId: "938806775042",
    appId: "1:938806775042:web:33cde53943691506f0861b",
    measurementId: "G-8WYJCH9X6Q",
  },
  // apiHost: "https://stagingapi-cr.valuechecker.net",
  //USE BELOW FOR LOCAL TESTING
  apiHost: "http://localhost/api",
  categoryPicPath:
    "https://files.valuechecker.net/images/default-category-images/",
  defaultPicUrl:
    "https://files.valuechecker.net/images/default-category-images/all_other_products.jpg",
  emailEndpoint:
    "https://vc-emails-staging-nmmhc72bla-ew.a.run.app/bca/send_email/",
  categoryPredictionPath:
    "https://category-prediction-staging-nmmhc72bla-ew.a.run.app/api/v1",
  actionLogsCFUrl:
    "https://europe-west1-vc-bicc-claimant-app-staging.cloudfunctions.net/collect-and-sink-cup-log-staging",
  mobileResolution: 768,
  tabletResolution: 1280,
  menuIconPath: "assets/icons/dropdown-icons/",
  valueCheckerUrl: "https://staging.valuechecker.net/",
  gcpEndpoint:
    "https://clouderrorreporting.googleapis.com/v1beta1/projects/vc-bicc-claimant-app-staging/",
  gcpProjectId: "vc-bicc-claimant-app-staging",
  gcpLoggerClient: "cup_action_logs_staging",
  cfApiKey: "NjP6mkzNnntBbAmg2IhQJKHulJTlnLUE",
  // Change for local development.
  isAdP: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
