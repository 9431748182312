import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/compat/firestore";

@Injectable({
  providedIn: "root",
})
export class LogsService {
  constructor(private _firestore: AngularFirestore) {}

  /**
   * Get Client reference data from firestore.
   *
   * @returns Logs object from the logs collection in Firestore.
   */
  public getErrorLogs() {
    return this._firestore.collection("error_logs");
  }

  /**
   * Retrieves the entire error document.
   *
   * @returns An Observable emitting the error data.
   */
  public getLogType(logType: string) {
    return this._firestore.collection("logs").doc(logType).valueChanges();
  }

  /**
   * Retrieves the document of session all session replays.
   *
   * @returns The document of session replays.
   */
  public getActionLogs(): AngularFirestoreCollection {
    return this._firestore.collection("action_logs");
  }
}
