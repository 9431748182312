import { AngularFirestore } from "@angular/fire/compat/firestore";
import { TranslateLoader } from "@ngx-translate/core";

export class CustomTranslateLoader implements TranslateLoader {
  constructor(private firestore: AngularFirestore) {}

  getTranslation(lang = "en") {
    return this.firestore.collection("translations").doc(lang).valueChanges();
  }
}
