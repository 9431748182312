import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";

import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ClientReferencesService {
  constructor(
    private _firestore: AngularFirestore,
    private _http: HttpClient
  ) {}

  /**
   * Get Client reference data from firestore.
   *
   * @returns Client reference object from the firestore collection.
   */
  public getClientReferences() {
    return this._firestore.collection("client_references");
  }

  /**
   * Load inventory products to cart.
   *
   * @remarks
   * When the user submits the inventory list, two things should happen,
   * an email should be sent to the claim handler indicating that the
   * customer has submitted the list and inventory list should be
   * automatically loaded to the VC cart page. This function sends
   * a request to VC backend which loads the products from BCA and
   * triggers "auto-rcv" calculation.
   *
   * @param clientId - Client id of the user.
   * @param clientReferenceId - Client reference id of the user.
   * @param apiKey - Apikey required to make api call to VC backend.
   */
  public loadCartProducts(
    clientId: number,
    clientReferenceId: number,
    apiKey: string
  ) {
    const apiHost = environment.apiHost;
    const apiUrl = `${apiHost}/client_reference/${clientReferenceId}/bca/load`;

    let headers = new HttpHeaders();
    headers = headers.set("authorization", `apiKey ${apiKey}`);

    const params = new HttpParams().set("client_id", String(clientId));
    this._http.get(apiUrl, { params: params, headers: headers }).subscribe();
  }

  /**
   * Loads recoverable depreciation data to the cart.
   *
   * @remarks
   * This function sends a request to the backend to load recoverable depreciation
   * data into the cart. It uses the provided client reference hash and API key
   * for authorization.
   *
   * @param clientReferenceHash - The hash of the client reference.
   * @param apiKey - The API key required for authorization.
   */
  public loadRDToCart(clientReferenceHash: string, apiKey: string) {
    const apiHost = environment.apiHost;
    const apiUrl = `${apiHost}/recoverable_depreciation/cup/load/}`;

    let headers = new HttpHeaders();
    headers = headers.set("authorization", `apiKey ${apiKey}`);

    const params = new HttpParams().set(
      "client_reference_hash",
      clientReferenceHash
    );
    this._http.get(apiUrl, { params: params, headers: headers }).subscribe();
  }
}
