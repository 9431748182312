import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

import { TranslationEntry } from "src/app/models/translation-entry";

@Injectable({ providedIn: "root" })
export class TranslationResolver {
  constructor(private translateService: TranslateService) {}

  /**
   * Resolves the translation data for route navigation.
   *
   * @remarks
   * Fetches the translation for the current language from the
   * TranslateService.
   *
   * @returns An Observable that emits the translation data.
   */
  resolve(): Observable<TranslationEntry> {
    return this.translateService.getTranslation(
      this.translateService.currentLang
    ) as Observable<TranslationEntry>;
  }
}
