import { Injectable } from "@angular/core";

import { Client } from "../enums/app.enum";

@Injectable({
  providedIn: "root",
})
export class ClientStylesService {
  /**
   * Handles styles for a specific clients.
   *
   * @remarks
   * This method applies custom styles to the document based on the
   * provided client ID. More clients can be added with their respective
   * customizations as needed.
   *
   * @param clientID - The unique identifier of the client.
   *
   * @example
   * ```typescript
   * handleCustomerStyles(335);
   * ```
   */
  public handleClientStyles(clientID: number): void {
    switch (clientID) {
      // Special Customization for Lemonade.
      case Client.LEMONADE: {
        document.body.classList.add("lemonade");
        const colorVariables = {
          "--primary-color": "#FF0083",
          "--primary-desktop-color": "#FF0083",
          "--light-blue": "#ffb3da",
          "--hover-dropdown-blue": "#FDF3F8",
          "--blue-primary": "#FF0083",
          "--bca-submit-light": "#ffb3da",
          "--outline-btn-hover-bg": "#FDF3F8",
          "--login-text-color": "#4A4A4A",
          "--room-text-color": "#4A4A4A",
          "--spec-toggle-color": "#696969",
          "--secondary-color": "#9B9B9B",
          "--text-primary": "#4A4A4A",
        };

        Object.entries(colorVariables).forEach(([key, value]) => {
          document.documentElement.style.setProperty(key, value);
        });

        const fontUrl =
          "https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap";

        const existingFontLink = document.querySelector(
          `link[href="${fontUrl}"]`
        );

        if (!existingFontLink) {
          // If font link not found, add it.
          const fontLink = document.createElement("link");
          fontLink.href = fontUrl;
          fontLink.rel = "stylesheet";
          document.head.appendChild(fontLink);

          // Update --primary-font to use Lato.
          document.documentElement.style.setProperty(
            "--primary-font",
            "Lato, 'Helvetica Neue', sans-serif"
          );
        }
      }
    }
  }
}
