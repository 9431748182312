import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Injectable({
  providedIn: "root",
})
export class ClientDataService {
  constructor(private firestore: AngularFirestore) {}

  getClientData() {
    return this.firestore.collection("client_data");
  }
}
