import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Injectable({
  providedIn: "root",
})
export class PublicHashesService {
  constructor(private firestore: AngularFirestore) {}

  getPublicHashes() {
    return this.firestore.collection("public_hashes");
  }
}
